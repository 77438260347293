/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/Mrwq70YKg69
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

/** Add fonts into your Next.js project:

import { Arimo } from 'next/font/google'
import { IBM_Plex_Sans } from 'next/font/google'

arimo({
  subsets: ['latin'],
  display: 'swap',
})

ibm_plex_sans({
  subsets: ['latin'],
  display: 'swap',
})

To read more about using these font, please visit the Next.js documentation:
- App Directory: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
- Pages Directory: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
**/

/** Add border radius CSS variable to your global CSS:

:root {
  --radius: 0.25rem;
}
**/
export function Homepage() {
  return (
    <div className="flex min-h-screen flex-col">
      <header className="bg-gray-900 py-4 px-6 text-white">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center">
            <img alt="CubedGold" className="h-8 mr-4" src="/placeholder.svg" />
            <h1 className="text-2xl font-bold">CubedGold</h1>
          </div>
          <nav className="hidden md:flex space-x-4">
            <a className="hover:underline" href="#">
              Home
            </a>
            <a className="hover:underline" href="#">
              Features
            </a>
            <a className="hover:underline" href="#">
              Events
            </a>
            <a className="hover:underline" href="#">
              Shop
            </a>
            <a className="hover:underline" href="#">
              Join Us
            </a>
          </nav>
          <button className="md:hidden">
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6h16M4 12h16M4 18h16"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
              />
            </svg>
          </button>
        </div>
      </header>
      <main className="flex-1">
        <section className="bg-gray-900 py-20 px-6">
          <div className="container mx-auto flex flex-col items-center justify-center">
            <h2 className="text-4xl font-bold text-white mb-4">
              Welcome to CubedGold
            </h2>
            <p className="text-gray-400 mb-8">
              Experience the ultimate Minecraft server with semi-RP and farm2win
              features.
            </p>
            <div className="flex space-x-4">
              <a
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                href="#"
              >
                Play.CubedGold.fr
              </a>
              <a
                className="bg-transparent hover:bg-gray-700 text-white font-bold py-2 px-4 rounded border border-gray-500"
                href="#"
              >
                Discord
              </a>
            </div>
          </div>
        </section>
        <section className="bg-gray-800 py-20 px-6" id="features">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-white mb-8">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="Profession System"
                  className="h-16 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  Profession System
                </h3>
                <p className="text-gray-400">
                  Explore a variety of professions and level them up to unlock
                  unique abilities and rewards.
                </p>
              </div>
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="Leveling System"
                  className="h-16 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  Leveling System
                </h3>
                <p className="text-gray-400">
                  Gain experience through various activities and progress
                  through levels to become more powerful.
                </p>
              </div>
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="Events"
                  className="h-16 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">Events</h3>
                <p className="text-gray-400">
                  Participate in exciting in-game events and competitions for
                  rewards and recognition.
                </p>
              </div>
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="In-Game Shop"
                  className="h-16 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  In-Game Shop
                </h3>
                <p className="text-gray-400">
                  Browse and purchase a variety of items, tools, and upgrades to
                  enhance your gameplay experience.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-900 py-20 px-6" id="events">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-white mb-8">
              Upcoming Events
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-gray-800 rounded-lg p-6">
                <img
                  alt="Event 1"
                  className="rounded-lg mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  PvP Tournament
                </h3>
                <p className="text-gray-400 mb-4">
                  Join the ultimate PvP showdown and compete for valuable
                  prizes.
                </p>
                <a
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                  href="#"
                >
                  Join Now
                </a>
              </div>
              <div className="bg-gray-800 rounded-lg p-6">
                <img
                  alt="Event 2"
                  className="rounded-lg mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  Building Contest
                </h3>
                <p className="text-gray-400 mb-4">
                  Show off your building skills and win amazing rewards.
                </p>
                <a
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                  href="#"
                >
                  Join Now
                </a>
              </div>
              <div className="bg-gray-800 rounded-lg p-6">
                <img
                  alt="Event 3"
                  className="rounded-lg mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  Survival Challenge
                </h3>
                <p className="text-gray-400 mb-4">
                  Test your survival skills in a challenging environment and
                  compete for the top spot.
                </p>
                <a
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                  href="#"
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-800 py-20 px-6" id="shop">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-white mb-8">In-Game Shop</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="Item 1"
                  className="h-32 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  Enchanted Sword
                </h3>
                <p className="text-gray-400 mb-4">
                  Unleash your power with this powerful enchanted sword.
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-yellow-500 font-bold">$9.99</span>
                  <a
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                    href="#"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="Item 2"
                  className="h-32 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  Farming Kit
                </h3>
                <p className="text-gray-400 mb-4">
                  Boost your farming productivity with this handy kit.
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-yellow-500 font-bold">$4.99</span>
                  <a
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                    href="#"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
              <div className="bg-gray-900 rounded-lg p-6">
                <img
                  alt="Item 3"
                  className="h-32 mb-4"
                  src="/placeholder.svg"
                />
                <h3 className="text-xl font-bold text-white mb-2">
                  XP Booster
                </h3>
                <p className="text-gray-400 mb-4">
                  Accelerate your leveling progress with this XP booster.
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-yellow-500 font-bold">$2.99</span>
                  <a
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                    href="#"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-900 py-20 px-6" id="join">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-white mb-8">
              Join CubedGold
            </h2>
            <div className="bg-gray-800 rounded-lg p-8">
              <h3 className="text-2xl font-bold text-white mb-4">
                Connect to the Server
              </h3>
              <p className="text-gray-400 mb-8">
                To join the CubedGold Minecraft server, simply connect using the
                following details:
              </p>
              <div className="bg-gray-900 rounded-lg p-4 mb-4">
                <p className="text-white font-bold">Server Address:</p>
                <p className="text-gray-400">cubedgold.mc.com</p>
              </div>
              <div className="bg-gray-900 rounded-lg p-4 mb-8">
                <p className="text-white font-bold">Server Version:</p>
                <p className="text-gray-400">1.19.2</p>
              </div>
              <a
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                href="#"
              >
                Get Started
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-gray-900 py-8 px-6 text-white">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center">
            <img alt="CubedGold" className="h-8 mr-4" src="/placeholder.svg" />
            <p>© 2023 CubedGold. All rights reserved.</p>
          </div>
          <nav className="hidden md:flex space-x-4">
            <a className="hover:underline" href="#">
              Privacy Policy
            </a>
            <a className="hover:underline" href="#">
              Terms of Service
            </a>
            <a className="hover:underline" href="#">
              Contact Us
            </a>
          </nav>
        </div>
      </footer>
    </div>
  );
}
